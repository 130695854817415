import { Ref, computed } from '@vue/composition-api';
import * as R from 'ramda';
import { format } from 'sql-formatter';
import { identify } from 'sql-query-identifier';
import { IdentifyResult } from 'sql-query-identifier/lib/defines';
import { SQLRetrievalMethod } from '../constants';
import { ApolloTask, BigQueryHarvesterConfiguration, SQLHarvesterConfiguration } from '../types';

export function useSQLQuery(task: Ref<ApolloTask<BigQueryHarvesterConfiguration | SQLHarvesterConfiguration>>) {
    const retrievalDetails: Ref<{
        method: SQLRetrievalMethod;
        query: string | null;
        table: string | null;
        freshDataColumn?: string | null;
        identifierColumns?: string[];
    }> = computed(() =>
        R.pick(['method', 'query', 'table', 'freshDataColumn', 'identifierColumns'], task.value.configuration),
    );

    const sqlType = computed((): {
        dialect: 'generic' | 'bigquery' | 'mysql' | 'psql' | 'mssql';
        language: 'sql' | 'bigquery' | 'mysql' | 'postgresql' | 'tsql' | 'mariadb';
    } => {
        if ((task.value.configuration as SQLHarvesterConfiguration).sqlType) {
            switch ((task.value.configuration as SQLHarvesterConfiguration).sqlType) {
                case 'mssql':
                    return { dialect: 'mssql', language: 'tsql' };
                case 'postgresql':
                    return { dialect: 'psql', language: 'postgresql' };
                case 'mysql':
                    return { dialect: 'mysql', language: 'mysql' };
                case 'mariadb':
                    return { dialect: 'mysql', language: 'mariadb' };
                default:
                    return { dialect: 'generic', language: 'sql' };
            }
        }
        return { dialect: 'bigquery', language: 'bigquery' };
    });

    const queryIncludesForbiddenTerms = computed(
        () =>
            retrievalDetails.value.method === SQLRetrievalMethod.Query &&
            !R.isNil(retrievalDetails.value.query) &&
            !!retrievalDetails.value.query.match(
                /([^a-zA-Z0-9]|^)(delete|drop|truncate|create|insert|update|alter|merge)([^a-zA-Z0-9]|$)/gi,
            ),
    );

    const parsedQuery = computed(() => {
        if (retrievalDetails.value.method === SQLRetrievalMethod.Query && retrievalDetails.value.query)
            try {
                return identify(retrievalDetails.value.query, { dialect: sqlType.value.dialect });
            } catch {
                return null;
            }
        return undefined;
    });

    const queryIncludesModification = computed(() =>
        parsedQuery.value?.some((value: IdentifyResult) => !['LISTING', 'INFOMATION'].includes(value.executionType)),
    );

    const uniqueColumns = computed(() =>
        retrievalDetails.value.identifierColumns
            ? retrievalDetails.value.identifierColumns
            : retrievalDetails.value.freshDataColumn
            ? [retrievalDetails.value.freshDataColumn]
            : [],
    );

    const sqlQueryFromStatement = computed(() => {
        if (retrievalDetails.value.method === SQLRetrievalMethod.Query && retrievalDetails.value.query) {
            const finalQuery = retrievalDetails.value.query;
            if (uniqueColumns.value.length === 0) return finalQuery;
            return `SELECT * FROM (${
                retrievalDetails.value.query.endsWith(';')
                    ? retrievalDetails.value.query.slice(0, -1)
                    : retrievalDetails.value.query
            })`;
        }
        return null;
    });

    const backQuote = computed(() => (sqlType.value.dialect === 'bigquery' ? `\`` : ''));

    const sqlQueryFromTable = computed(() => {
        if (retrievalDetails.value.method === SQLRetrievalMethod.Table && retrievalDetails.value.table) {
            const finalQuery = `SELECT * FROM ${backQuote.value}${retrievalDetails.value.table}${backQuote.value}`;
            if (uniqueColumns.value.length === 0) return `${finalQuery};`;
            return finalQuery;
        }
        return null;
    });

    const sqlQueryPreview = computed(() => {
        let finalQuery = sqlQueryFromStatement.value ?? sqlQueryFromTable.value;

        if (!finalQuery) return '';

        if (uniqueColumns.value.length > 0) finalQuery += ' WHERE ';

        for (let i = 0; i < uniqueColumns.value.length; i++) {
            finalQuery += `${backQuote.value}${uniqueColumns.value[i]}${backQuote.value} > 'LAST_RECORD'`;
            if (i + 1 < uniqueColumns.value.length) finalQuery += ' AND ';
        }

        if (uniqueColumns.value.length > 0) finalQuery += ' ORDER BY ';

        for (let i = 0; i < uniqueColumns.value.length; i++) {
            finalQuery += `${backQuote.value}${uniqueColumns.value[i]}${backQuote.value} ASC`;
            if (i + 1 < uniqueColumns.value.length) finalQuery += ', ';
        }

        if (uniqueColumns.value.length > 0) finalQuery += ';';

        try {
            return format(finalQuery, {
                language: sqlType.value.language,
                indentStyle: 'tabularLeft',
                keywordCase: 'upper',
            });
        } catch {
            return finalQuery;
        }
    });

    return { queryIncludesForbiddenTerms, parsedQuery, queryIncludesModification, sqlQueryPreview };
}
